<template>
  <div id="kt_footer" class="footer bg-white py-4 d-flex flex-lg-column">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1" :class="{ small: isMobileDevice }">
        <span class="text-muted font-weight-bold mr-2">2024© | Version {{ $store.getters.appVersion }}</span>
        <a href="https://opalean.fr/" target="_blank" class="text-dark-75 text-hover-primary" v-translate>Opatrace &trade; by Opalean</a>
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2" v-if="!isMobileDevice">
        <a v-for="(link, index) in footerLinks" :key="index" :href="link.url" target="_blank" class="nav-link pr-3 pl-0">{{ $gettext(link.name) }}</a>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statics from "@/core/statics/statics.js";

export default {
  name: "KTFooter",
  data: function () {
    return {
      footerLinks: statics.footerLinks,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "getDevice"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },

    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
};
</script>
